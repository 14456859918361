<template>
  <nav>
    <ul>
      <li v-for="(item, index) of totalPassos" :class="{active: item.index === currentStep}" :key="index">
        <span>
          {{item.description}}
        </span>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'progress-bar',
  props: {
    currentStep: Number,
    totalPassos: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped>
  nav {
    margin-top: 50px;
    align-self: center;
    ul {
      display: flex;
      list-style: none;
      padding: 0;
      li {
        max-width: 190px;
        width: 170px;
        border: 1px solid #FDB00B;
        display: flex;
        color: #FDB00B;
        background: #4B335A;
        height: 45px;
        border-radius: 17px;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
        padding: 0 5px;
        word-break: break-all;
      }
      li.active {
        color: #4B335A;
        background-color: #FDB00B;
        border-style: none;
      }
    }
  }
@media screen and (max-width: 768px){
  nav{
      margin-top: 30px!important;
    ul{
      li{
        width: 70px;
        padding: 2px 8px;
        font-size: 12px;
        margin: 0 1%;
        border-radius: 12px;
        span {
          font-size: 30px;
          width: 30px;
          height: 30px;
          line-height: 30px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
