<template>
  <div :class="classes">
    <p v-if="items.length === 0" class="not-found-content"><slot name="emptyItem">
      {{infinitiveExpression ? 'Nenhum' : 'Nenhuma'}} {{label}} adicionad{{infinitiveExpression ? 'o' : 'a'}}.
      Preenche o formulário com os dados de {{ labels || label}} e clica em adicionar.</slot>
    </p>
    <table v-else class="table-form-profissional">
      <thead>
        <th style="width: 28px"></th>
        <slot name="head">
          <th v-for="header in headers" :key="header">{{header}}</th>
        </slot>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in items" :key="idx">
          <td>
            <img class="btn-delete" src="@/assets/img/bin.svg" alt="" @click="() => removeItem(idx)" />
          </td>
          <slot name="item" :item="item">
            <td>{{ item.designacao }}</td>
            <td>{{ item.instituicaoEnsino }}</td>
            <td>{{ item.idGrauAcademico && grauAcademico(item.idGrauAcademico).nome }}</td>
            <td>{{ item.idAreaEstudo && areaEstudo(item.idAreaEstudo).nome}}</td>
          </slot>
          <slot name="cell">
          </slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'table-form',
  props: {
    items: Array,
    label: {
      type: String,
      default: 'item'
    },
    labels: String,
    classes: {
      type: [Array, Object],
      default: () => []
    },
    infinitiveExpression: {
      type: Boolean
    },
    headers: {
      type: Array,
      default: () => ['Curso', 'Instituição', 'Tipo', 'Área de estudo']
    }
  },
  methods: {
    removeItem (id) {
      this.$emit('remove-item', id)
    }
  },
  computed: {
    ...mapGetters('register', [
      'areaEstudo',
      'grauAcademico'
    ])
  }
}
</script>

<style lang="scss" scoped>
div {
  width: 100%;

  @media screen and (max-width: 600px) {
    &.mobile .table-form-profissional {
      thead {
        display: none;
      }
      tbody, tbody tr{
        display: flex;
        flex-direction: column;
      }
      tr {
        margin-bottom: 20px;
        &:first-child {
          margin-top: 20px;
        }
      }
    }
  }
}
.not-found-content {
  color: #bababa;
  text-align: center;
  padding: 10px 0;
}
.table-form-profissional {
  width: 100%;
  text-align: left;
  border: 1px solid;
  border-radius: 6px;
  padding: 0 20px;
  color: #6a4c7cc0;
  th {
    font-size: 16px;
  }
}
.btn-delete {
  width: 16px;
  cursor: pointer;
  &:active {
    opacity: 0.7;
  }
}
</style>
