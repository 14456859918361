<template>
  <table>
    <table-caption :noController="true"
    :label="`${month[parseInt(currentDate.month / 4)][currentDate.month % 4]} ${currentDate.year}`"
    @callback="$emit('toggle-calendar', 'date')"/>
    <tbody>
      <tr v-for="(month, index) in months" :key="index">
        <td v-for="(name, index2) in month"
        :key="index2"
        :class="{active: currentDate.month === (index * matrix) + index2}"
        @click="$emit('set-month', (index * matrix) + index2 )">
        <slot name="date" v-bind:data="{value: name}" /></td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'calendar-month',
  components: {
    'table-caption': require('./caption').default
  },
  props: {
    currentDate: Object,
    month: {
      type: Array,
      default: function () {
        return [
          ['Jan', 'Fev', 'Mar', 'Abril'],
          ['Maio', 'Jun', 'Jul', 'Ago'],
          ['Set', 'Out', 'Nov', 'Dez']
        ]
      }
    }
  },
  data () {
    return {
      matrix: 4,
      YEAR: this.currentYear,
      months: this.month
    }
  }
}
</script>

<style scoped>
tbody td {
  width: 76px;
  height: 45px;
}
.active {
  color: #FDB00B;
}
</style>
