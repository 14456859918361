import { url } from '@/api/base-url'

export function getNacionalidades () {
  return url.get('Nacionalidades')
}

export function getPaises () {
  return url.get('Paises')
}

export function getAreasFormacao () {
  return url.get('AreaFromacoes')
}
export function getGrauAcademicos () {
  return url.get('GrausAcademicos')
}

export function getIdiomas () {
  return url.get('Idiomas')
}
export function getPerguntas () {
  return url.get('Perguntas')
}

export function getIdiomaNiveis () {
  return url.get('IdiomaNiveis')
}
export function getCidades () {
  return url.get('Cidades')
}
export async function addDadosPessoais (candidato) {
  let result = null
  result = await url.post('Candidatos', { ...candidato })
  return result
}

export function addDadosAcademico (educacao) {
  return url.post('Candidatos/AddEducacao', { ...educacao })
}

export async function addIdioma (idioma) {
  let result = await url.post('Candidatos/AddIdioma', { ...idioma })
  return result
}

export function addDisponibilidade (disponibilidade) {
  return url.post('Candidatos/AddDisponiblidade', { ...disponibilidade })
}

export function addRespostas (resposta) {
  return url.post('Candidatos/AddQuestionario', resposta)
}
export async function validateEmail (email) {
  let res = await url.get('auth/validateEmail?email=' + email)
  return res.data.object
}
