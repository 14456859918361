<template>
  <base-form class="row"
  @submeter="submeter"
  @back-step="handlerBack"
  @close-dropdown="closeModalInput"
  :loading="loading" :loadingTitle="loadingTitle">
  <confirm-modal
  v-if="greeting"
  @confirm="goToHome"
  ModalTitle="Bem-vindo à RISE Work!<br/>Consulta o teu email para confirmares o teu registo."
  >
  <template #textButton>Fechar</template>
  </confirm-modal>
    <container-fluid>
      <header-title slot="header"
      title="4. Experiência Profissional"
      description="Insere até três experiências profissionais que consideres mais relevantes e descreve as principais responsabilidades." />
      <div class="section-container form-container">
        <section class="section">
            <div class="input-wrapper">
              <input-form
                title="Empresa"
                :error="errors.empresa[Object.keys(errors.empresa)[0]] ? Object.keys(errors.empresa)[0] : ''"
                class="add-margin"
                dataType="text"
                placeholder=""
                v-model="empresa"
                @focus="resetWidget"
              />
            </div>
            <div class="input-wrapper">
              <input-datalist
                title="Sector"
                :error="errors.sector[Object.keys(errors.sector)[0]] ? Object.keys(errors.sector)[0] : ''"
                field="w_isSector"
                :isShow="w_isSector"
                @setData="(value) => setData('sector', value)"
                placeholder=""
                :value="sector.nome"
                class="item"
                :dataSource="sectores"
                :themeStyle="theme"
                v-on:w_isSector="toggleWidget"
              />
            </div>
            <div class="input-wrapper">
            <input-datalist
                title="Função"
                :error="errors.funcao[Object.keys(errors.funcao)[0]] ? Object.keys(errors.funcao)[0] : ''"
                field="w_isFuncao"
                :isShow="w_isFuncao"
                @setData="(value) => setData('funcao', value)"
                placeholder=""
                :value="funcao.nome"
                class="item"
                :dataSource="funcoes"
                :themeStyle="theme"
                v-on:w_isFuncao="toggleWidget"
              />
            </div>
        </section>
        <section class="section">
            <div class="input-wrapper">
              <input-datalist
                title="Duração"
                :error="errors.experiencia[Object.keys(errors.experiencia)[0]] ? Object.keys(errors.experiencia)[0] : ''"
                field="w_isExperiencia"
                fieldName="designacao"
                :isShow="w_isExperiencia"
                @setData="(value) => setData('experiencia', value)"
                placeholder=""
                :value="experiencia.designacao"
                class="item"
                :dataSource="experiencias"
                :themeStyle="theme"
                v-on:w_isExperiencia="toggleWidget"
              />
            </div>
            <div class="input-wrapper">
              <p class="label-wrappper-title2">Descrição/Principais Responsabilidades </p>
              <span class="message-error"
              :style="{color: '#f00', fontSize: '12px', display: 'block', textAlign: 'left'}">
                {{
                  errors.mensagem[Object.keys(errors.mensagem)[0]] ?
                  Object.keys(errors.mensagem)[0] : ''
                }}
              </span>
              <textarea
                placeholder=""
                type="text"
                name=""
                v-model="mensagem"
                :class="['textarea-description', mensagem && 'isContent']"
                @focus="resetWidget"
                rows="5"
              />
            </div>
            <div style="text-align: right">
              <button-add v-if="experienciasProfissional.length < 3" @add-register="addExperiencia"/>
            </div>
        </section>
      </div>
    </container-fluid>
    <container-fluid>
        <div class="section-container">
            <table-form :classes="['mobile']" label="experiência profissional" :items="experienciasProfissional" @remove-item="removeExperiencia">
              <template #head>
                <th>Empresa</th>
                <th>Sector</th>
                <th>Função</th>
                <th>Experiência</th>
                <th>Descrição</th>
              </template>
              <template #item="{ item }">
                <td>{{item.empresa}}</td>
                <td>{{getFormacao(item.idSector).nome}}</td>
                <td>{{getFuncao(item.idFuncao).nome}}</td>
                <td>{{getExperiencia(item.idExperiencia).designacao}}</td>
                <td style="max-width: 300px">{{item.descricao}}</td>
              </template>
            </table-form>
        </div>
        <app-separator class="margin-vertical"/>
    </container-fluid>
    <container-fluid>
      <header-title title="Auto - Avaliação" description="Diz-nos como te descreves, identificando 3 pontos fortes e 3 áreas de melhoria."/>
      <div class="form-container section-container">
        <section class="section-left section">
          <div class="input-wrapper">
            <input-datalist
              title="Pontos fortes"
              field="w_isValencia"
              :isShow="w_isValencia"
              @setData="(value) => setData('valencia', value)"
              placeholder=""
              :value="valencia.nome"
              class="item"
              :dataSource="getValenciasAvaliable"
              :themeStyle="theme"
              v-on:w_isValencia="toggleWidget"
            />
          </div>
          <div style="text-align: right">
            <button-add @add-register="addPontosFortes"/>
          </div>
          <section class="details-info-container">
          <table-form label="ponto forte" labels="pontos fortes" :infinitiveExpression="true" :items="pontosFortes" @remove-item="removePontosFortes">
            <template #head>
              <th></th>
            </template>
            <template #item="{ item }">
              <td>{{getValencia(item).descricao}}</td>
            </template>
            <template #emptyItem>
              Nenhum ponto forte adicionado. Preenche o formulário com os teus pontos fortes e clica em adicionar.
            </template>
          </table-form>
        </section>
        </section>
        <section class="section-left section">
          <div class="input-wrapper">
            <input-datalist
              title="Pontos de melhoria"
              field="w_isMelhoria"
              :isShow="w_isMelhoria"
              @setData="(value) => setData('melhoria', value)"
              placeholder=""
              :value="melhoria.nome"
              class="item mt"
              :dataSource="getValenciasAvaliable"
              :themeStyle="theme"
              v-on:w_isMelhoria="toggleWidget"
            />
            <div style="text-align: right">
              <button-add @add-register="addPontosMelhoria"/>
            </div>
            <section class="details-info-container">
              <div class="data-container-1 data-container">
              <table-form label="ponto de melhoria" labels="pontos de melhoria" :infinitiveExpression="true" :items="pontosMelhorias" @remove-item="removePontosMelhoria">
                <template #head>
                  <th></th>
                </template>
                <template #item="{ item }">
                  <td>{{getValencia(item).descricao}}</td>
                </template>
                <template #emptyItem>Nenhum ponto de melhoria adicionado. Preenche o formulário com os teus pontos de melhoria e clica em adicionar.</template>
              </table-form>
              </div>
            </section>
          </div>
        </section>
      </div>
    </container-fluid>
    <container-fluid>
      <header-title  title="Vídeo CV" description="Queremos conhecer-te melhor! Submete um vídeo com até 1 minuto de duração com uma breve apresentação do teu percurso profissional, formação académica e competências relevantes para a(s) tua(s) área(s) de actuação." />
      <div class="form-container section-container flex-start" :style="{flexDirection: 'column'}">
        <section class="section">
            <div class="input-wrapper">
              <p class="label-wrappper-title2">
                <span :style="{color: '#f00', fontSize: '12px'}">{{
                  errors.videoCVUrlTmp[Object.keys(errors.videoCVUrlTmp)[videoIndexError]] ? '('+Object.keys(errors.videoCVUrlTmp)[videoIndexError]+')' : ''
                }}</span>
              </p>
              <button class="input-file">
                <input type="file" id="file-input" accept="video/*" @change="setVideo"/>
                <label for="file-input">{{videoCVUrlTmp ? 'ficheiro carregado ' : 'Carregar ficheiro'}} {{videoCVUrlTmp && videoCVUrlTmp.name | nameFile}}</label>
                <div class="progress"></div>
              </button>
            </div>
        </section>
        <div class="hint-video-cv">
          <p>Dicas para fazeres um bom Vídeo CV</p>
          <ol class="list-hint">
            <li>Adapta a tua linguagem verbal, postura e aparência como se fosse uma entrevista.</li>
            <li>Apresenta-te com um “Olá” de forma natural e amigável, e diz como te chamas.</li>
            <li>Diz-nos qual a tua formação e experiência(s) relevante(s).</li>
            <li>Destaca as tuas conquistas e potencialidades relacionadas com a função a que te propões candidatar.</li>
            <li>Diz-nos quais os idiomas dominas.</li>
            <li>Practica o discurso várias vezes até obteres mais confiança e naturalidade.</li>
            <li>Não te esqueças de sorrir.</li>
          </ol>
          <p>Boa sorte!</p>
        </div>
      </div>
      <app-separator class="margin-vertical" />
    </container-fluid>
    <container-fluid>
      <header-title title="Onde estás disponível para trabalhar?" />
      <div class="section-container form-container">
        <section class="section">
          <div class="input-wrapper">
            <input-datalist
              title=""
              :error="errors.disponibilidade[Object.keys(errors.disponibilidade)[0]] ? Object.keys(errors.disponibilidade)[0] : ''"
              field="w_isDisponibilidade"
              align="top desktop-top"
              :isShow="w_isDisponibilidade"
              @setData="(value) => setData('disponibilidade', value)"
              placeholder=""
              :value="disponibilidade.nome"
              class="item"
              :dataSource="disponibilidades"
              :themeStyle="theme"
              v-on:w_isDisponibilidade="toggleWidget"
            />
          </div>
          <div style="text-align: right">
            <button-add @add-register="addDisponibilidade"/>
          </div>
        </section>
      </div>
    </container-fluid>
    <container-fluid>
      <div class="section-container">
        <div class="section">
          <div>
            <group-card-row :items="cidades">
              <card-tag
                v-for="(cidade, idx) in cidades" :key="idx"
                :title="`${getDisponibilidades(cidade).nome}`"
                @delete-item="() => removeDisponibilidade(idx)"
              />
              <p slot="notItem">Nenhuma disponibilidade adicionada. Preenche o formulário com os dados da disponibilidade e clica em adicionar</p>
            </group-card-row>
          </div>
        </div>
      </div>
      <app-separator class="margin-vertical" />
    </container-fluid>
  </base-form>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { createCandidate, createFile } from '@/api/services/register'
export default {
  name: 'form-data-submission',
  components: {
    'base-form': require('@/components/Form/base-form').default,
    'input-datalist': require('@/components/Form/input-datalist').default,
    'input-form': require('@/components/Form/input-form').default,
    'button-add': require('@/components/Form/button-add').default,
    'confirm-modal': require('@/components shared/Modal/ConfirmModal2').default,
    'table-form': require('@/components/Form/table-form').default,
    'card-tag': require('@/components/Form/card-tag').default,
    'group-card-row': require('@/components/Form/group-card-row').default
  },
  props: {
    theme: Object
  },
  computed: {
    ...mapGetters('register', [
      'perguntas',
      'experiencias',
      'duracoes',
      'funcoes',
      'valencias',
      'areasFormacao',
      'sectores',
      'disponibilidades',
      'getFormacao',
      'getFuncao',
      'getValencia',
      'getPergunta',
      'getExperiencia',
      'getDisponibilidades'
    ]),
    ...mapState('register', ['register']),
    getValenciasAvaliable () {
      let avaliacao = this.valencias.filter(item => !this.pontosMelhorias.includes(item.id))
      avaliacao = avaliacao.filter(item => !this.pontosFortes.includes(item.id))
      return avaliacao
    }
  },
  filters: {
    nameFile (name) {
      return name.length < 40 ? name : name.substr(0, 10) + '...' + name.substr(name.length - 10)
    }
  },
  data () {
    const experienciasProfissional = this.$store.state.register.register.experienciasProfissional
    const pontosFortes = this.$store.state.register.register.pontosFortes
    const pontosMelhorias = this.$store.state.register.register.pontosMelhorias
    const disponibilidades = this.$store.state.register.register.disponibilidades
    return {
      w_items: [],
      w_isSector: false,
      w_isFuncao: false,
      w_isDuracao: false,
      w_isValencia: false,
      w_isExperiencia: false,
      w_isMelhoria: false,
      w_isDisponibilidade: false,
      sector: '',
      funcao: '',
      empresa: '',
      duracao: '',
      valencia: '',
      melhoria: '',
      mensagem: '',
      loading: false,
      experiencia: '',
      pontosFortes,
      pontosMelhorias,
      disponibilidade: '',
      loadingTitle: '',
      pontoCriticos: '',
      loadVideoCv: false,
      experienciasProfissional,
      cidades: disponibilidades,
      videoCVUrlTmp: this.$store.state.register.register.videoCVUrlTmp,
      videoIndexError: 0,
      auxPontosFortes: [],
      auxPontosMelhorias: [],
      Avaliacao: [],
      greeting: false,
      aux: [],
      textError: '',
      errors: {
        empresa: {
          'Empresa inválida': false
        },
        sector: {
          'Sector inválido': false
        },
        funcao: {
          'Função inválida': false
        },
        experiencia: {
          'Experiência inválida': false
        },
        disponibilidade: {
          'Disponibilidade inválida': false
        },
        mensagem: {
          'Mensagem mandatória': false
        },
        videoCVUrlTmp: {
          'Video CV mandatório': false,
          'limite do seu video CV é de 300MB': false,
          'O teu CV deve estar no formato de vídeo': false
        }
      }
    }
  },
  methods: {
    ...mapMutations('register', ['setState', 'resetState']),
    setData (field, value) {
      this[field] = value
      this.resetWidget()
    },
    goToHome () {
      this.greeting = false
      this.$router.push({ path: '/' })
    },
    closeModalInput (isCloseModal) {
      if (isCloseModal) {
        this.resetWidget()
      }
    },
    returnFileSize (number) {
      let value = 0
      let unit = 'bytes'
      if (number < 1024) {
        value = number
        unit = 'bytes'
      } else if (number >= 1024 && number < 1048576) {
        value = (number / 1024).toFixed(1)
        unit = 'KB'
      } else if (number >= 1048576) {
        value = (number / 1048576).toFixed(1)
        unit = 'MB'
      }
      return {
        value: parseFloat(value),
        unit
      }
    },
    setVideo (e) {
      this.videoIndexError = 0
      Object.keys(this.errors.videoCVUrlTmp).forEach(error => {
        this.errors.videoCVUrlTmp[error] = false
      })
      const video = e.target.files[0]
      const size = this.returnFileSize(video.size)
      if (!size) return
      if (size.unit === 'MB' && size.value >= 300) {
        this.videoIndexError = 1
        const property = Object.keys(this.errors.videoCVUrlTmp)[this.videoIndexError]
        this.errors.videoCVUrlTmp[property] = true
        return
      }
      if (e.target.files[0].type.indexOf('video/') === -1) {
        this.videoIndexError = 2
        const property = Object.keys(this.errors.videoCVUrlTmp)[this.videoIndexError]
        this.errors.videoCVUrlTmp[property] = true
        return
      }
      this.videoCVUrlTmp = e.target.files[0]
      this.setState({ videoCVUrlTmp: e.target.files[0] })
    },
    handlerBack () {
      const { pontosFortes, pontosMelhorias, experienciasProfissional, cidades } = this
      this.setState({
        pontosFortes,
        pontosMelhorias,
        experienciasProfissional,
        disponibilidades: cidades
      })
      this.$emit('change-step', 2)
    },
    valideField (field) {
      const key = Object.keys(this.errors[field])[0]
      this.errors[field][key] = false
    },
    toggleWidget (property, value) {
      this.resetWidget()
      this[property] = !value
    },
    resetWidget () {
      this.w_isSector = false
      this.w_isFuncao = false
      this.w_isDuracao = false
      this.w_isResposta = false
      this.w_isValencia = false
      this.w_isMelhoria = false
      this.w_isExperiencia = false
      this.w_isDisponibilidade = false
    },
    submeter () {
      const { pontosFortes, pontosMelhorias, experienciasProfissional, cidades, videoCVUrlTmp } = this

      if (pontosFortes.length < 3) {
        alert('Selecione no mínimo 3 pontos fortes.')
        return
      }
      if (pontosMelhorias.length < 3) {
        alert('Selecione no mínimo 3 pontos de melhoria.')
        return
      }
      if (cidades.length === 0) {
        alert('Deve adicionar uma opção de disponibilidade')
        return
      }
      if (videoCVUrlTmp === '') {
        const property = Object.keys(this.errors.videoCVUrlTmp)[this.videoIndexError]
        this.errors.videoCVUrlTmp[property] = true
        return
      }

      this.setState({
        pontosFortes,
        pontosMelhorias,
        experienciasProfissional,
        disponibilidades: cidades
      })
      this.loading = true
      this.loadingTitle = 'A submeter vídeo  CV'
      createFile(this.register.videoCVUrlTmp).then(res => {
        this.loadingTitle = 'vídeo cv carregado com sucesso...'
        setTimeout(() => {
          this.registerCandidato(res.data.object)
        }, 2000)
      }).catch(e => {
        this.loadingTitle = 'erro ao processar o vídeo CV' + '#'
        setTimeout(() => {
          this.loading = false
        }, 4000)
      })
    },
    registerCandidato (file = '') {
      this.loadingTitle = 'Processando a sua candidatura...'
      setTimeout(() => {
        createCandidate({ ...this.register, videoCVUrlTmp: file }).then(res => {
          this.loadingTitle = 'A sua candidatura foi concluída'
          setTimeout(() => {
            this.loading = false
            this.greeting = true
            this.resetState()
          }, 2000)
        }).catch(e => {
          if (e.response) {
            this.loadingTitle = e.response.data.message + '#'
          } else {
            this.loadingTitle = e.message
          }
        }).finally(e => {
          setTimeout(() => {
            this.loading = false
          }, 4000)
        })
      }, 2000)
    },
    addExperiencia () {
      const { empresa, mensagem, sector, funcao, experiencia, errors } = this

      let ok = true
      Object.keys(this.errors).forEach(field => this.valideField(field))
      if (sector === '') {
        const property = Object.keys(errors.sector)[0]
        this.errors.sector[property] = true
        ok = false
      }
      if (funcao === '') {
        const property = Object.keys(errors.funcao)[0]
        this.errors.funcao[property] = true
        ok = false
      }
      if (experiencia === '') {
        const property = Object.keys(errors.experiencia)[0]
        this.errors.experiencia[property] = true
        ok = false
      }
      if (mensagem === '') {
        const property = Object.keys(errors.mensagem)[0]
        this.errors.mensagem[property] = true
        ok = false
      }
      if (empresa === '') {
        const property = Object.keys(errors.empresa)[0]
        this.errors.empresa[property] = true
        ok = false
      }
      if (!ok) {
        return
      }

      this.experienciasProfissional.unshift({
        empresa,
        descricao: mensagem,
        idSector: sector.id,
        idFuncao: funcao.id,
        idExperiencia: experiencia.id
      })
      this.idExperiencia = ''
      this.empresa = ''
      this.mensagem = ''
      this.sector = ''
      this.funcao = ''
      this.duracao = ''
      this.experiencia = ''
    },
    removeExperiencia (id) {
      this.experienciasProfissional.splice(id, 1)
    },
    addPontosFortes () {
      const { valencia } = this
      if (valencia && !this.auxPontosMelhorias.find(item => item.id === valencia.id)) {
        this.pontosFortes.push(valencia.id)
      } else {
        return
      }
      this.valencia = ''
      this.melhoria = ''
    },
    removePontosFortes (id) {
      this.pontosFortes.splice(id, 1)
    },
    addPontosMelhoria () {
      const { melhoria } = this
      if (melhoria && !this.auxPontosFortes.find(item => item.id === melhoria.id)) {
        this.pontosMelhorias.push(melhoria.id)
      } else {
        return
      }
      this.melhoria = ''
      this.valencia = ''
    },
    removePontosMelhoria (id) {
      this.pontosMelhorias.splice(id, 1)
    },
    addDisponibilidade () {
      const { disponibilidade } = this
      if (disponibilidade && disponibilidade.nome === 'Todas') {
        this.cidades = []
        this.cidades.push(disponibilidade.id)
      } else if (disponibilidade && !this.cidades.find(cidade => cidade === disponibilidade.id)) {
        const cidade = this.disponibilidades.find(c => c.nome === 'Todas')
        if (cidade && this.cidades.includes(cidade.id)) {
          this.cidades.forEach((item, id) => {
            if (item === cidade.id) {
              this.removeDisponibilidade(id)
            }
          })
        }
        this.cidades.push(disponibilidade.id)
      } else {
        return
      }
      this.disponibilidade = ''
    },
    removeDisponibilidade (id) {
      this.cidades.splice(id, 1)
    },
    setDuracao (value) {
      this.duracao = value
      this.resetWidget()
    }
  },
  created () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.description-formacao,
.description-outros {
  width: 100%;
}
.message-error {
  position: absolute;
  top: 33px;
  left: 6px;
}
.mb {
  margin-bottom: 160px;
}
.data-title {
  text-align: left;
}
.input-file {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 5px;
  height: 60px;
  border: 1px solid #4B335A;
  background-color: #fff;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: background-color .3s ease-out;
  .progress {
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 5px;
    left: 0;
    background-color: #4B335A;
  }
  &:hover {
    background-color: #f2f2f2;
  }
  [type = file] {
    display: none;
  }
  label {
    font-family: 'arial';
    color: #aaa;
    text-align: left;
    line-height: 3.8em;
    font-size: 14px;
    cursor: pointer;
    padding-left: 20px;
    bottom: 0px;
    display: block;
    width: 100%;
    height: 100%;
  }
}
.hint-video-cv {
  color: #6a4c7cc0;
  margin-left: 28px;
  ol {
    padding-left: 16px;
    li {
      line-height: 1.3em;
      margin: 10px 0;
    }

  }
  p {
    margin-bottom: 18px;
  }
  @media screen and (min-width: 768px) {
      font-size: 21px;
      max-width: 70%;
    margin-top: 30px;
    ol {
      margin-bottom: 30px;
      padding-left: 18px;
      li {
      line-height: 1.5em;
    }
    }

  }
}
.textarea-description {
  padding: 12px;
  margin-top: 0;
  line-height: 1.7em;
  padding-bottom: 0;
  width: 100%;
  font-weight: 100;
  border: 2px solid #6a4c7cc0;
  color: #f8f8f8;
  border-radius: 5px;
  font-size: 16px;
  caret-color: #4B335A;
  &::placeholder {
    color: #888;
    font-size: 20px;
  }
  &.isContent {
    background-color: #50366054;
  }
}
.details-info {
  display: none !important;
}
.details-info-container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  position: relative;
  margin-top: 30px;
  .details-subtitle {
    font-size: 1.2em;
    line-height: 1.4em;
  }
}
.details-info-container {
  width: 100%;
  display: flex;
  position: relative;
  .btn-delete {
    width: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.4;
    }
  }
  .data-title {
    font-size: 18px;
    line-height: 1.2em;
    font-weight: bolder;
  }
  p {
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.2em;
    color: #9d9d9d;
  }
}
.section-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  .section {
    margin: 0;
    width: 46%;
  }
  .input-wrapper, .input-row {
    position: relative;
  }
  .input-wrapper {
    margin-top: 6px;
  }
}
.details-info-container {
  display: flex;
  align-items: flex-start;
  .data-container {
    width: 100%;
  }
}
.label-wrappper-title2 {
  font-size: 18px;
  color: rgba(106, 76, 124, 0.753);
  text-align: left;
  font-family: "Roboto", sans-serif;
  left: 0;
}
@media only screen and (max-width: 768px) {
  .margin-left {
    margin-left: 25px;
  }
  .details-info-container {
    flex-direction: column;
    width: 100%;
    .data-container {
      flex: 1;
      width: 100%;
      & + .data-container {
        margin-left: 0;
      }
    }
    .btn-delete.type-2 {
      order: -1;
      align-self: center;
    }
  }
  .width-middle {
    width: 100%;
  }
  .section-container {
    flex-direction: column;
    align-items: center;
    .input-row {
      flex-direction: column;
      align-items: flex-start;
    }
    .input-wrapper, .input-row {
      width: 98%;
    }
    .input-wrapper {
      margin: 1rem 0;
    }
    .section {
      width: 100%;
      &:nth-child(2) {
        align-items: flex-start;
      }
    }
  }
}
</style>
