<template>
  <div>
      {{data.value}}
  </div>
</template>
<script>
export default {
  name: 'table-cell',
  props: {
    type: {
      type: String,
      default: 'simples'
    },
    data: {
      type: [String, Number, Object]
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  font-size: 1rem;
}
div:hover {
  background-color: rgba(255, 255, 255, 0.212);
  cursor: pointer;
}
</style>
