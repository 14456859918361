<template>
  <div class="container">
    <progress-button :currentStep="currentForm" :totalPassos="itemsSteps"/>
    <section class="forms">
      <form-data-pessoal
        :theme="style"
        v-if="currentForm === 0"
        @change-step="setCurrentForm"
      />
      <form-data-academic
        :theme="style"
        v-if="currentForm === 1"
        @change-step="setCurrentForm"
      />
      <form-language-aviability
        :theme="style"
        v-if="currentForm === 2"
        @change-step="setCurrentForm"
      />
      <form-data-submission
        :theme="style"
        v-if="currentForm === 3"
        @change-step="setCurrentForm"
      />
    </section>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'form-page',
  data () {
    return {
      currentForm: 0,
      itemsSteps: [{
        index: 0,
        description: '1. Dados Pessoais'
      }, {
        index: 1,
        description: '2. Educação'
      },
      {
        index: 2,
        description: '3. Formação'
      },
      {
        index: 3,
        description: '4. Experiência'
      }],
      style: {
        color: '#503660',
        backgroundStyle: {
          color: '#503660'
        },
        textStyle: {
          color: '#fff'
        }
      }
    }
  },
  components: {
    'progress-button': require('@/components/Form/progress-button').default,
    'form-data-pessoal': require('@/components/Form/form-data-pessoal').default,
    'form-data-academic': require('@/components/Form/form-data-academic').default,
    'form-language-aviability': require('@/components/Form/form-formation').default,
    'form-data-submission': require('@/components/Form/form-data-submission').default
  },
  methods: {
    ...mapActions('register', ['getPicklists']),
    setCurrentForm (current) {
      window.scrollBy({ top: 0, behavior: 'smooth' })
      this.currentForm = current
    }
  },
  mounted () {
    this.loading = true
    this.getPicklists().finally(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "NexaLight", sans-serif;
  .forms {
    display: flex;
    margin-top: 30px;
  }
  footer {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    button {
      border-style: none;
      background-color: #503660;
      color: #ffffff;
      width: 100px;
      height: 40px;
      cursor: pointer;
    }
    .next {
      color: rgba(255, 255, 255, 0.425);
      position: relative;
    }
    .next::before {
      content: "";
      display: block;
      width: 4px;
      height: 100%;
      padding: 0;
      background-color: #fdb00b;
      position: absolute;
      left: -2px;
      top: 0;
    }
  }
}
</style>
