<template>
  <div class="align-right" :class="{'border': items.length}">
    <div v-if="items.length">
      <slot/>
    </div>
    <div v-else class="not-found-content">
      <slot name="notItem" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'group-card-tag-row',
  props: {
    items: {
      type: Array
    }
  }
}
</script>
<style lang="scss" scoped>
.not-found-content {
  color: #bababa;
  text-align: center;
  padding: 10px 0;
}
.align-right {
  display: inline-block;
  width: 100%;
  &.border {
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  }
}
</style>
