<template>
  <span class="card-tag">
    <img class="btn-delete"
    src="@/assets/img/bin.svg"
    width="16"
    alt=""
    @click="$emit('delete-item')"
    loading="lazy">
    {{title}}
  </span>
</template>

<script>
export default {
  name: 'card-tag',
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
.card-tag {
  display: inline-flex;
  align-items: center;
  margin: 0 5px;
  padding: 4px 6px;
  border-radius: 20px;
  color: #8F789C;
  &:hover {
    background: rgba(62, 32, 230, 0.055);
  }
  img {
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
