<template>
  <base-form
    @submeter="submeter"
    :loading="false"
    :lock="true"
    :disable-submit-button="false"
    @back-step="$emit('change-step', 0)"
    @close-dropdown="closeModalInput"
  >
    <container-fluid>
      <header-title title="2. Educação" description="Coloca as tuas formações académicas mais relevantes."/>
      <div class="form-container width-middle-2">
          <div class="input-wrapper">
            <input-datalist
              title="Grau académico"
              :error="errors.habilitacao[Object.keys(errors.habilitacao)[0]] ? Object.keys(errors.habilitacao)[0] : ''"
              field="w_isHabilitacao"
              :isShow="w_isHabilitacao"
              @setData="(value) => setData('hability', value)"
              placeholder=""
              :value="hability.nome"
              :dataSource="habilitacoes"
              :themeStyle="theme"
              v-on:w_isHabilitacao="toggleWidget"
            />
          </div>
      </div>
    <app-separator />
    </container-fluid>
    <container-fluid>
      <div class="section-container">
        <section class="section">
          <div class="input-wrapper">
            <input-form
            title="Nome do Curso"
              :error="errors.curso[Object.keys(errors.curso)[0]] ? Object.keys(errors.curso)[0] : ''"
              placeholder=""
              v-model="$v.curso.$model"
              @focus="resetWidget"
            />
          </div>
          <div class="input-wrapper">
            <input-form
              title="Nome da Instituição"
              :error="errors.instituicao[Object.keys(errors.instituicao)[0]] ? Object.keys(errors.instituicao)[0] : ''"
              v-model="$v.instituicao.$model"
              @focus="resetWidget"
              :text="$v.instituicao.$model"
            />
          </div>
        </section>
        <section class="section">
          <div class="input-wrapper">
            <input-datalist
              title="Tipo"
              align="top"
              class="mt"
              :error="errors.tipo[Object.keys(errors.tipo)[0]] ? Object.keys(errors.tipo)[0] : ''"
              field="w_isTipo"
              :isShow="w_isTipo"
              @setData="(value) => setData('tipo', value)"
              placeholder=""
              :value="tipo.nome"
              :dataSource="grausAcademicos"
              :themeStyle="theme"
              v-on:w_isTipo="toggleWidget"
            />
          </div>
          <div class="input-wrapper">
            <input-datalist
              title="Área de estudo"
              align="top desktop-top"
              class="mt"
              :error="errors.area[Object.keys(errors.area)[0]] ? Object.keys(errors.area)[0] : ''"
              field="w_isAria"
              :isShow="w_isAria"
              @setData="(value) => setData('area', value)"
              placeholder=""
              :value="area.nome"
              :dataSource="areasEstudo"
              :themeStyle="theme"
              v-on:w_isAria="toggleWidget"
            />
          </div>
        </section>
      </div>
      <div style="text-align: right">
        <button-add class="btn-right" v-if="educacoes.length < 2" @add-register="addEducacao"/>
      </div>
    </container-fluid>
      <template>
        <div class="details">
          <container-fluid>
            <form-table :classes="['mobile']" label="educação" :items="educacoes" @remove-item="(id) => removeEducacao(id) "/>
            <app-separator class="margin-vertical" />
          </container-fluid>
        </div>
    </template>
  </base-form>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import BaseForm from '@/components/Form/base-form'
export default {
  name: 'form-data-academic',
  components: {
    'base-form': BaseForm,
    'input-form': require('@/components/Form/input-form').default,
    'input-datalist': require('@/components/Form/input-datalist').default,
    'header-title': require('@/components/Form/header-title').default,
    'container-fluid': require('@/components/Form/container-fluid').default,
    'button-add': require('@/components/Form/button-add').default,
    'form-table': require('@/components/Form/table-form').default
  },
  props: {
    theme: Object
  },
  data () {
    const hability = this.$store.getters['register/getHabilityId'](this.$store.state.register.register.idHabilitacao)
    return {
      w_isAria: false,
      w_isTipo: false,
      showModal: false,
      w_isHabilitacao: false,
      area: '',
      tipo: '',
      hability,
      curso: '',
      textError: '',
      loading: false,
      instituicao: '',
      DetailsInfo: true,
      invalidForm: false,
      educacoes: this.$store.state.register.register.educacoes,
      errors: {
        curso: {
          'Curso inválido': false
        },
        instituicao: {
          'Instituição inválida': false
        },
        habilitacao: {
          'Habilitação inválida': false
        },
        tipo: {
          'Tipo inválido': false
        },
        area: {
          'Área de estudo inválida': false
        }
      }
    }
  },
  validations: {
    instituicao: {
      required
    },
    curso: {
      required
    }
  },
  methods: {
    ...mapMutations('register', ['setState']),
    toggleWidget (property, value) {
      this.resetWidget()
      this[property] = !value
    },
    closeModalInput (isCloseModal) {
      if (isCloseModal) {
        this.resetWidget()
      }
    },
    valideField (field) {
      const key = Object.keys(this.errors[field])[0]
      this.errors[field][key] = false
    },
    addEducacao () {
      const { tipo, area, $v, errors } = this
      const { curso, instituicao } = $v
      let ok = true
      Object.keys(this.errors).forEach(field => this.valideField(field))
      if (curso.$model === '' || curso.$error) {
        const property = Object.keys(errors.curso)[0]
        this.errors.curso[property] = true
        ok = false
      }
      if (instituicao.$model === '' || instituicao.$error) {
        const property = Object.keys(errors.instituicao)[0]
        this.errors.instituicao[property] = true
        ok = false
      }
      if (tipo === '') {
        const property = Object.keys(errors.tipo)[0]
        this.errors.tipo[property] = true
        ok = false
      }
      if (area === '') {
        const property = Object.keys(errors.area)[0]
        this.errors.area[property] = true
        ok = false
      }

      if (!ok) {
        return
      }
      if (!tipo && !area && !$v.curso.$model && !$v.instituicao.$model) return
      this.educacoes.unshift({
        idAreaEstudo: area.id,
        idGrauAcademico: tipo.id,
        designacao: $v.curso.$model,
        instituicaoEnsino: $v.instituicao.$model
      })

      this.resetWidget()
      this.area = ''
      this.tipo = ''
      this.curso = ''
      this.instituicao = ''
    },
    removeEducacao (id) {
      this.educacoes.splice(id, 1)
    },
    resetWidget () {
      this.w_isTipo = false
      this.w_isAria = false
      this.w_isHabilitacao = false
    },
    submeter () {
      const { hability, educacoes, errors } = this
      let ok = true
      if (hability === '' && educacoes.length === 0) {
        const property = Object.keys(errors.habilitacao)[0]
        this.errors.habilitacao[property] = true
        ok = false
      }
      if (!ok) {
        return
      }
      if (educacoes.length === 0) {
        alert('Nenhuma educação adicionada. Preenche o formulário com os dados de educação e clica em adicionar.')
        return
      }
      this.setState({
        idHabilitacao: hability.id,
        educacoes: educacoes
      })
      this.$emit('change-step', 2)
    },
    setData (field, value) {
      this[field] = value
      this.resetWidget()
    }
  },
  computed: {
    ...mapGetters('register', [
      'areaEstudo',
      'areasEstudo',
      'habilitacoes',
      'grauAcademico',
      'grausAcademicos'
    ]),
    ...mapState('register', ['register']),
    disabledForm () {
      const { $v, educacoes } = this
      return !($v.$invalid === false || educacoes.length !== 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.details  {
  width: 100%;
  padding-top: 10px;
}
.form-container {
  margin-bottom: 50px;
  &.width-middle {
    width: 50%;
    .input-container {
      flex: 0.8;
    }
  }
  &.width-middle-2 {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 44%;
    }
  }
}
.details-info-container {
  width: 98%;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-top: 20px;
  position: relative;
  .data-title, .details-title {
    font-size: 18px;
    margin: 5px 0;
    line-height: 1.2em;
    font-weight: bolder;
  }
  p {
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.2em;
    color: #9d9d9d;
  }
  .data-container-1 {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .data-container-2 {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}
.section-container {
  display: flex;
  justify-content: center;
  width: 100%;
  .section {
    flex: 0.8;
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .input-wrapper, .input-row {
    width: 88%;
  }
  .input-wrapper {
    margin-top: 6px;
  }
  .input-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .input-wrapper {
      flex: 0.45;
      &.flex-1 {
        flex: 0.3;
      }
      &.flex-2 {
        flex: 0.65
      }
    }
  }
}
.label-wrappper-title2 {
  font-size: 18px;
  color: #4b335a;
  text-align: left;
  font-family: "Roboto", sans-serif !important;
  left: 0;
}
@media only screen and (max-width: 768px) {
  .margin-left {
    margin-left: 25px;
  }
  .form-container.width-middle {
    width: 100%;
  }
  .section-container {
    flex-direction: column;
    align-items: center;
    .input-row {
      flex-direction: column;
      align-items: flex-start;
    }
    .input-wrapper, .input-row {
      width: 100%;
    }
    .input-wrapper {
      margin: 1rem 0;
    }
    .section {
      width: 100%;
      &:nth-child(2) {
        align-items: flex-start;
      }
    }
  }
}
</style>
