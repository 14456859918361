<template>
  <div role="calendar" class="calendar widget">
      <calendar-date v-if="ActiveCalendar.localeCompare('date') === 0"
      :currentDate="currentDate"
      :dayWeek="dayWeek"
      :checkout="checkout"
      :nowDate="activeDate"
      @setDate="setCheckout"
      @backMonth="() =>goMonth(-1)"
      @nextMonth="() => goMonth(1)"
      @toggleCalendar="(calendar) => toggleCalendar(calendar)"
      >
      <template #date="{data}">
        <table-cell :data="data" class="cell"/>
      </template>
      </calendar-date>

      <calendar-month v-else-if="ActiveCalendar.localeCompare('month') === 0"
      :currentDate="currentDate"
      @set-month="setMonth"
      @toggle-calendar="(calendar) => toggleCalendar(calendar)"
      >
       <template #date="{data}">
        <table-cell :data="data" />
      </template>
      </calendar-month>

      <calendar-year v-else
      :currentYear="currentDate.year"
      @set-year="setYear"
      :minAge="18"
      @toggle-calendar="(calendar) => toggleCalendar(calendar)">
       <template #date="{data}">
        <table-cell :data="data" />
      </template>
      </calendar-year>
  </div>
</template>
<script>
export default {
  name: 'widget-calendar',
  components: {
    'calendar-month': require('./month').default,
    'calendar-date': require('./date').default,
    'calendar-year': require('./year').default,
    'table-cell': require('./cell').default
  },
  props: {
    value: [String, Date],
    minAge: {
      type: Number,
      default: 18
    }
  },
  data () {
    const d = this.value ? new Date(this.value) : new Date(new Date().getFullYear() - this.minAge, 0, 1)
    return {
      ActiveCalendar: 'date',
      checkout: {
        date: null,
        month: null,
        year: null
      },
      activeDate: {
        date: d.getDate(),
        year: d.getFullYear(),
        month: d.getMonth()
      },
      currentDate: {
        day: d.getDay(),
        date: d.getDate(),
        year: d.getFullYear(),
        month: d.getMonth()
      },
      dayWeek: [
        'dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'
      ],
      months: ['jan', 'fev', 'mar', 'abril', 'maio', 'jun', 'jul', 'set', 'out', 'nov', 'dez']
    }
  },

  methods: {
    setMonth (month) {
      this.currentDate.month = month
      this.ActiveCalendar = 'date'
    },
    setCheckout (value) {
      this.checkout = value
      this.$emit('selectDate', new Date(value.year, value.month + 1, value.date))
    },
    toggleCalendar (nextCalendar) {
      this.ActiveCalendar = nextCalendar.toLowerCase()
    },
    goMonth (value) {
      const year = new Date().getFullYear() - this.minAge
      if (value < 0) {
        this.currentDate.month--
      } else if (value > 0) {
        this.currentDate.month++
      }
      const d = new Date(this.currentDate.year, this.currentDate.month, 1)
      if (d.getFullYear() > year && d.getMonth() === 0) {
        this.currentDate.month--
        return
      }
      this.currentDate.date = d.getDate()
      this.currentDate.month = d.getMonth()
      this.currentDate.year = d.getFullYear()
      this.currentDate.day = d.getDay()
      this.checkout = { data: null, month: null, year: null }
    },
    goYear (value) {
      if (value < 0) {
        this.currentDate.year--
      } else if (value > 0) {
        this.currentDate.year++
      }
      const d = new Date(this.currentDate.year, this.currentDate.month, this.currentDate.date)
      this.currentDate.date = d.getDate()
      this.currentDate.month = d.getMonth()
      this.currentDate.year = d.getFullYear()
      this.currentDate.day = d.getDay()
      this.checkout = { data: null, month: null, year: null }
    },
    setYear (year) {
      this.currentDate.year = year
      this.ActiveCalendar = 'month'
      this.goYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.widget {
    position: absolute;
    left: -10px;
    top: -180%;
    width: 340px;
    z-index: 99;
    text-align: left;
    color: #fff;
    background-color: #4B335A;
    overflow: hidden;
    overflow-y: auto;
    padding: 0;
    border-radius: 6px;
    display: flex;
    justify-content: center;
}
table {
  padding: 0;
  border-collapse: collapse;
}
.cell {
  color: rgba(255, 255, 255, 0.788);
}

</style>
