<template>
  <base-form
  @submeter="submeter"
  @back-step="$emit('change-step', 1)"
  @close-dropdown="closeModalInput"
  :loading="false"
  :lock="true">
  <container-fluid>
    <header-title title="3. Cursos & Idiomas" description="Insere até três cursos/formações mais relevantes que tenhas realizado e diz-nos os teus idiomas." />
    <div class="section-container">
      <section class="section">
        <div class="input-wrapper">
          <input-form
          title="Curso"
          :error="errors.curso[Object.keys(errors.curso)[0]] ? Object.keys(errors.curso)[0] : ''"
          placeholder=""
          v-model="curso"
          @focus="resetWidget"/>
        </div>
        <div class="input-wrapper">
          <input-form
          title="Instituição/Entidade"
          :error="errors.instituicao[Object.keys(errors.instituicao)[0]] ? Object.keys(errors.instituicao)[0] : ''"
          placeholder=""
          v-model="instituicao"
          @focus="resetWidget"/>
        </div>
      </section>
      <section class="section">
          <div class="input-wrapper">
            <input-datalist
              title="Duração"
              :error="errors.duracao[Object.keys(errors.duracao)[0]] ? Object.keys(errors.duracao)[0] : ''"
              field="w_isDuration"
              :isShow="w_isDuration"
              @setData="(value) => setData('duration',value)"
              placeholder=""
              :value="duration.nome"
              class="item mt-0"
              :dataSource="duracoes"
              :themeStyle="theme"
              v-on:w_isDuration="toggleWidget"
            />
          </div>
          <div class="input-wrapper">
            <input-datalist
              title="Área de formação"
              :error="errors.area[Object.keys(errors.area)[0]] ? Object.keys(errors.area)[0] : ''"
              field="w_isArea"
              :isShow="w_isArea"
              @setData="(value) => setData('area', value)"
              placeholder=""
              :value="area.nome"
              class="item mt-0"
              :dataSource="areasFormacao"
              :themeStyle="theme"
              v-on:w_isArea="toggleWidget"
            />
          </div>
      </section>
    </div>
    <div style="text-align: right">
      <button-add v-if="formacoes.length < 3" @add-register="addFormacao"/>
    </div>
  </container-fluid>
  <container-fluid>
      <form-table :classes="['mobile']" label="formação" :items="formacoes" @remove-item="removeFormacao">
        <template #head>
          <th>Curso</th>
          <th>Instituição</th>
          <th>Duração</th>
          <th>Área de formação</th>
        </template>
        <template #item="{ item }">
          <td>{{ item.curso }}</td>
          <td>{{ item.instituicao }}</td>
          <td>{{ getDuracao(item.idDuracao).designacao }}</td>
          <td>{{ getFormacao(item.idAreaFormacao).nome }}</td>
        </template>
      </form-table>
    <app-separator class="margin-vertical" />
  </container-fluid>
  <container-fluid>
    <header-title title="Idioma" />
    <div class="container-row">
        <div class="input-wrapper">
          <input-datalist
            title="Língua materna"
            :error="errors.linguaMaterna[Object.keys(errors.linguaMaterna)[0]] ? Object.keys(errors.linguaMaterna)[0] : ''"
            field="w_isLinguaMaterna"
            :isShow="w_isLinguaMaterna"
            @setData="(value) => setData('linguaMaterna', value)"
            placeholder=""
            :value="linguaMaterna.nome"
            class="item"
            :dataSource="listaIdiomas"
            :themeStyle="theme"
            v-on:w_isLinguaMaterna="toggleWidget"
          />
        </div>
        <div class="input-wrapper">
          <input-datalist
          title="Selecciona outros idiomas"
          :error="errors.lingua1[Object.keys(errors.lingua1)[errors.lingua1.index]] ? Object.keys(errors.lingua1)[errors.lingua1.index] : ''"
          field="w_isLingua1"
          :isShow="w_isLingua1"
          @setData="(value) => setData('lingua1', value)"
          placeholder=""
          :value="lingua1.nome"
          :dataSource="listaIdiomas"
          class="item input"
          :themeStyle="theme"
          v-on:w_isLingua1="toggleWidget"
          />
          <div class="input-row nivel-idioma">
            <label v-for="(nivel, idx) in listaNivelIdioma" :key="idx" :for="`lingua${idx}f`">
              <input type="radio" v-model="nivel1" :value="nivel.id" name="lingua1" :id="`lingua${idx}f`">{{nivel.designacao}}
            </label>
          </div>
          <div style="text-align: right">
            <button-add @add-register="addIdioma"/>
          </div>
        </div>
    </div>
  </container-fluid>
  <container-fluid>
    <div class="description-idioma" style="text-align: right">
      <group-card-row :items="idiomas" style="text-align:left">
        <card-tag
          v-for="(idioma, idx) in idiomas" :key="idx"
          :title="`${getIdioma(idioma.idIdioma).nome} (${listaNivelIdioma.find(x => x.id === idioma.idNivelIdioma).designacao})`"
          @delete-item="() => removeIdioma(idx)"
        />
        <p slot="notItem">Nenhum idioma adicionado. Preenche o formulário com os dados de idiomas e clica em adicionar.</p>
      </group-card-row>
    </div>
    <app-separator />
  </container-fluid>
  </base-form>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'form-idioma-availability',
  components: {
    'base-form': require('@/components/Form/base-form').default,
    'input-datalist': require('@/components/Form/input-datalist').default,
    'input-form': require('@/components/Form/input-form').default,
    'button-add': require('@/components/Form/button-add').default,
    'form-table': require('@/components/Form/table-form').default,
    'card-tag': require('@/components/Form/card-tag').default,
    'group-card-row': require('@/components/Form/group-card-row').default
  },
  props: {
    theme: Object
  },
  data () {
    const formacoes = this.$store.state.register.register.formacoes
    const idiomas = this.$store.state.register.register.idiomas
    return {
      w_isOnde: false,
      w_isTipo: false,
      w_isArea: false,
      w_isCurso: false,
      w_isIdioma: false,
      w_isLingua1: false,
      w_isDuration: false,
      w_isLinguaMaterna: false,
      area: '',
      nivel: '',
      curso: '',
      lingua1: '',
      idiomas,
      duration: '',
      formacoes,
      instituicao: '',
      linguaMaterna: '',
      DetailsInfo: true,
      nivel1: undefined,
      disponibilidade: '',
      errors: {
        curso: {
          'Curso inválido': false
        },
        instituicao: {
          'Instituição inválida': false
        },
        duracao: {
          'Duração inválida': false
        },
        area: {
          'Área de formação inválida': false
        },
        linguaMaterna: {
          'Lingua materna mandatória ': false
        },
        lingua1: {
          'Escolher o Idioma': false,
          'Idioma já existe.': false,
          index: 0
        }
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters('register', ['duracoes',
      'grausAcademicos',
      'listaIdiomas',
      'disponiblidades',
      'listaNivelIdioma',
      'areasFormacao',
      'grauAcademico',
      'getFormacao',
      'getIdioma',
      'getDuracao']),
    ...mapState('register', ['register']),
    showOnde () {
      return this.disponibilidade ? this.disponibilidade.descricao.localeCompare('Não') !== 0 : false
    },
    showAddIdiomaButton () {
      return this.language.nivel.id !== -1
    }
  },
  validations: {
    instituicao: {
      required
    },
    curso: {
      required
    }
  },
  methods: {
    ...mapMutations('register', ['setState']),
    toggleWidget (property, value) {
      this.resetWidget()
      this[property] = !value
    },
    closeModalInput (isCloseModal) {
      if (isCloseModal) {
        this.resetWidget()
      }
    },
    valideField (field) {
      const key = Object.keys(this.errors[field])[0]
      this.errors[field][key] = false
    },
    resetWidget () {
      this.w_isIdioma = false
      this.w_isOnde = false
      this.w_isDuration = false
      this.w_isCurso = false
      this.w_isArea = false
      this.w_isTipo = false
      this.w_isLinguaMaterna = false
      this.w_isLingua1 = false
      this.w_isLingua2 = false
    },
    submeter () {
      const { formacoes, idiomas, linguaMaterna, errors } = this
      if (linguaMaterna === '') {
        const property = Object.keys(errors.linguaMaterna)[0]
        this.errors.linguaMaterna[property] = true
        return
      }
      this.setState({
        formacoes,
        idiomas,
        idLinguaMaterna: linguaMaterna.id
      })
      this.$emit('change-step', 3)
    },
    addFormacao () {
      const { duration, area, $v, errors } = this
      const { curso, instituicao } = $v
      let ok = true
      Object.keys(errors).forEach(field => this.valideField(field))
      if (curso.$model === '' || curso.$error) {
        const property = Object.keys(errors.curso)[0]
        this.errors.curso[property] = true
        ok = false
      }
      if (instituicao.$model === '' || instituicao.$error) {
        const property = Object.keys(errors.instituicao)[0]
        this.errors.instituicao[property] = true
        ok = false
      }
      if (duration === '') {
        const property = Object.keys(errors.duracao)[0]
        this.errors.duracao[property] = true
        ok = false
      }
      if (area === '') {
        const property = Object.keys(errors.area)[0]
        this.errors.area[property] = true
        ok = false
      }

      if (!ok) {
        return
      }
      this.formacoes.unshift({
        curso: curso.$model,
        instituicao: instituicao.$model,
        idDuracao: duration.id,
        idAreaFormacao: area.id
      })

      this.tipo = ''
      this.area = ''
      this.curso = ''
      this.duration = ''
      this.instituicao = ''
    },
    removeFormacao (id) {
      this.formacoes.splice(id, 1)
    },
    addIdioma () {
      const { lingua1, nivel1, errors } = this
      let ok = true
      Object.keys(errors).forEach(field => this.valideField(field))
      if (lingua1 === '') {
        const property = Object.keys(errors.lingua1)[0]
        this.errors.lingua1[property] = true
        this.errors.lingua1.index = 0
        ok = false
      }
      if (this.idiomas.find(idi => idi.idIdioma === lingua1.id)) {
        const prop = Object.keys(errors.lingua1)[1]
        this.errors.lingua1[prop] = true
        this.errors.lingua1.index = 1
        ok = false
      }
      if (!ok) {
        return
      }
      if (!nivel1) {
        alert('Selecciona o nível de conhecimento do idioma.')
        return
      }
      this.idiomas.push({
        idIdioma: lingua1.id,
        idNivelIdioma: nivel1
      })
      this.lingua1 = ''
      this.nivel1 = undefined
      Object.keys(this.errors.lingua1).forEach(prop => {
        if (prop !== 'index') {
          this.errors.lingua1[prop] = false
        }
      })
    },
    removeIdioma (id) {
      this.idiomas.splice(id, 1)
    },
    setFields (key, value) {
      this.language.idioma = value
      this.resetWidget()
    },
    setData (field, value) {
      this[field] = value
      this.resetWidget()
    },
    setNivel (value) {
      this.language.nivel = value
      this.resetWidget()
    },
    setDisponibilidade (value) {
      if (value.descricao.localeCompare('Não') === 0) {
        this.onde.id = 1
      }
      this.disponibilidade = value
      this.resetWidget()
    }
  },
  mounted () {
    this.linguaMaterna = this.$store.getters['register/getIdiomaId'](this.$store.state.register.register.idLinguaMaterna) || (this.listaIdiomas && this.listaIdiomas[0]) || ''
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.details {
  width: 100%;
}
.form-container {
  &.width-middle {
    width: 50%;
    .input-container {
      flex: 0.8;
    }
  }
}
.description-idioma {
  text-align: center!important;
}
.container-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  .input-wrapper {
    flex: 0.46;
  }
}
.not-found-content {
  color: #bababa;
  text-align: center;
  padding: 10px 0;
}
.align-right {
  width: 46%;
  display: inline-block;
  &.border {
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  }
}

.section-container {
  display: flex;
  justify-content: center;
  width: 100%;
  .section {
    flex: 0.8;
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .input-wrapper, .input-row {
    width: 88%;
  }
  .input-wrapper {
    margin-top: 6px;
  }
  .input-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .input-wrapper {
      flex: 0.45;
      &.flex-1 {
        flex: 0.3;
      }
      &.flex-2 {
        flex: 0.65
      }
    }
  }
}
.label-wrappper-title2 {
  font-size: 18px;
  color: #4b335a;
  text-align: left;
  font-family: "Roboto", sans-serif !important;
  left: 0;
}
.nivel-idioma {
  margin-top: 6px;
  width: 80%;
  justify-content: flex-start;
  display: flex;
  label {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
  }
  input{
    margin-right: 6px;
    &[type="radio"] {
      border: 1px solid #4B335A;
      position: relative;
      background: transparent;
      width: 12px;
      height: 12px;
      border: 1px solid #4B335A;
      border-radius: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      &:checked::after {
        content: '';
        position: absolute;
        background: #4B335A;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        top: 0.976px;
        left: 1.145px;
      }
    }
  }
}
.details-info-container {
  width: 100%;
  margin-top: 20px;
  max-width: 1000px;
  margin-bottom: 40px;
  display: flex;
  padding-top: 20px;
  position: relative;
  .description-language {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 6px 0;
  }
  .btn-delete {
    width: 20px;
    cursor: pointer;
  }
  .details-title {
    font-size: 18px;
    line-height: 1.2em;
    margin: 4px 0;
    font-weight: bolder;
  }
  p {
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.2em;
    color: #9d9d9d;
    b {
      font-size: .9em;
    }
  }
  .data-container-1 {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .data-container-2 {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .margin-left {
    margin-left: 25px;
  }
  .align-right {
    width: 100%;
  }
  .form-container.width-middle {
    width: 100%;
  }
  .section-container {
    flex-direction: column;
    align-items: center;
    .input-row {
      flex-direction: column;
      align-items: flex-start;
    }
    .input-wrapper, .input-row {
      width: 98%;
    }
    .input-wrapper {
      margin: 1rem 0;
    }
    .section {
      width: 100%;
      &:nth-child(2) {
        align-items: flex-start;
      }
    }
  }
}
</style>
