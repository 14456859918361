<template>
<div :class="[color]">
  <div class="title">{{title}}</div>
  <div
  class="input-control"
  :class="{ completed: completed || value, [color]: true }"
  data-input
  >
    <span class="error">{{error}}</span>
    <label @click="toggleWidget" :class="{placeholder: !value}">{{(value ? value.toLocaleDateString() : '') || placeholder}}</label>
    <template v-if="isShow">
      <app-calendar @selectDate="setData" :value="value" :themeStyle="themeStyle" />
    </template>
      <span class="btn-more" @click.prevent="toggleWidget" @keyup="handlerKeyUp" data-input tabindex="0">+</span>
  </div>
</div>
</template>
<script>
export default {
  name: 'input-calendar',
  components: {
    'app-calendar': require('@/components/widgets/calendar/calendar').default
  },
  props: {
    placeholder: String,
    value: [String, Date],
    dataSource: Array,
    themeStyle: Object,
    title: String,
    isShow: {
      type: Boolean,
      default: false
    },
    field: String,
    error: String,
    color: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      completed: false
    }
  },
  methods: {
    toggleWidget () {
      this.$emit(this.field, this.field, this.isShow)
      if (!this.isShow) {
        this.completed = false
      }
    },
    setData (item) {
      this.$emit('setData', item)
      this.completed = true
    },
    handlerKeyUp (e) {
      if (e.keyCode === 13) {
        e.target.click()
      }
    }
  },
  filters: {
    filterDate (date) {
      return date ? date.toLocaleDateString() : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.primary {
    color: #6a4c7cc0 !important;
  }
.btn-more {
  cursor: pointer;
}
.title {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px;
  color: inherit;
  font-size: 18px;
  text-align: left;
  font-family: "Roboto", sans-serif !important;
  left: 0;
}
.input-control {
  position: relative;
  background-color: #fff;
  border: 2px solid;
  width: 100%;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  label {
    width: 100%;
    flex: 1;
    border-style: none;
    outline-style: none;
    font-size: 16px;
    background: transparent;
    text-align: left;
    color: #eee;
    display: flex;
    align-items: center;
    &.placeholder {
      color: rgba(138, 133, 133, 0.295);
    }
  }
  .error {
   color: #f00;
    font-size: 12px;
    position: absolute;
    left: 5px;
    top: 0;
    font-weight: normal;
  }
  label {
    font-weight: 100;
    color: inherit;
    font-family: 'NexaLight';
    @media(max-width:560px){
      font-size: 16px!important;
    }
  }
  &.completed {
    background-color: #50366054;
    label {
      color: #fff;
    }
  }
  span {
    font-size: 1.5rem;
    font-weight: 900;
    color: orange;
    button {
      cursor: pointer;
      font-size: 1.5rem;
      font-weight: 900;
      color: orange;
      background-color: transparent;
      border-style: none;
    }
  }
}
</style>
