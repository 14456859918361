<template>
  <main class="modal-avatar">
    <button class="modal-btn-close" @click="$emit('close-modal')">X</button>
    <div class="modal-container">
        <div class="container">
          <p class="avatar-title">Seleciona um avatar</p>
          <section class="section-container">
            <template  v-for="(item, index) in avatares">
              <span :key="index">
                <img
                  @click="selectImage(item)"
                  :src="item.avatarUrl"
                  alt="" srcset=""
                />
                <!-- <p>{{item.designacao}}</p> -->
              </span>
            </template>
          </section>
        </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ModalAvatar',
  computed: {
    ...mapGetters('register', ['avatares'])
  },
  mounted () {
  },
  methods: {
    selectImage (img) {
      this.$emit('selectImage', img)
      this.$emit('close-modal')
    },
    toggleEd () {
      this.edContainer = !this.edContainer
      this.edText = !this.edText
      this.rotateToggleEd = !this.rotateToggleEd
      if (this.edContainer === false) {
        this.epContainer = true
        this.epText = true
        this.rotateToggleEp = true
      }
    },
    toggleEp () {
      this.epContainer = !this.epContainer
      this.epText = !this.epText
      this.rotateToggleEp = !this.rotateToggleEp
      if (this.epContainer === false) {
        this.edContainer = true
        this.edText = true
        this.rotateToggleEd = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-avatar{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto auto;
    z-index: 999998;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    opacity: 1;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    transition: opacity 0.3s ease;
  }
  .modal-btn-close{
    position: absolute;
    top: 15%;
    right: 10%;
    width: 43px;
    height: 43px;
    font-weight: bold;
    font-size: 16px;
    z-index: 777;
    -webkit-box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
    box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
    border: none;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
  .modal-container{
    width: 70%;
    height: 70vh;
    display: flex;
    border-radius: 5px;
    box-shadow: 7px 2px 7px #00000029;
    .container{
      padding: 4%;
      flex: 1;
      border: #00000029 1px solid;
      background-color: #fff;
      display: flex;
      height: 100%;
      overflow-y: auto;
      flex-direction: column;
      position: relative;
      .section-container{
        flex: 1;
        background-color: #FDB00B;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media(min-width:768px){
          flex-wrap: wrap;
        }
        img{
          width: 120px;
          height: 120px;
          margin: 10px;
          border-radius: 6px;
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: 758px){
    .modal-btn-close {
      right: 2%;
    }
    .modal-container {
      border: 1px solid;
      .section-container {
        flex-direction: column;
      }
    }
  }
</style>
