<template>
  <table>
    <table-caption @back="$emit('backMonth')"
    @next="$emit('nextMonth')"
    :label="currentDate | DateNow"
     @callback="$emit('toggleCalendar', 'year')"
    />
      <thead>
        <tr>
          <th v-for="(day, index) in dayWeek" :key="index">{{day}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(week, index) in date" :key="index">
          <template  v-for="(day, index2) in week">
            <td v-if="day.month == currentDate.month"
            :key="index2" :class="{
            notMonth: day.month !== currentDate.month,
            'date-now': day.month === nowDate.month && day.date === nowDate.date && currentDate.year === nowDate.year ? true:  false,
            'date-mark': day.date === checkout.date
            }"
          @click="$emit('setDate', day)"
          >
          <slot name="date" v-bind:data="{value: day.date}"/>
          </td>
          <td v-else
            :key="index2" class="notMonth"
          >{{day.date}}
          </td>
          </template>

        </tr>
      </tbody>
  </table>
</template>
<script>
export default {
  name: 'calendar-month',
  components: {
    'table-caption': require('./caption').default
  },
  props: {
    currentDate: {
      type: Object
    },
    nowDate: {
      type: Object
    },
    dayWeek: {
      type: Array,
      default: () => []
    },
    checkout: {
      type: Object,
      default: function () {
        return {
          date: null,
          month: null
        }
      }
    }
  },
  filters: {
    DateNow (value) {
      const months = ['jan', 'fev', 'mar', 'abril', 'maio', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
      const d = new Date(value.year, value.month, value.date)
      return months[d.getMonth()] + ' ' + d.getFullYear()
    }
  },
  computed: {
    date () {
      const month = []
      const d = new Date(this.currentDate.year, this.currentDate.month, 1)
      d.setDate(1)
      const day = d.getDay() - 1
      d.setDate(0)
      d.setDate(d.getDate() - day)
      let count = 0
      for (let i = 0; i < 6; i++) {
        month[i] = []
        for (let j = 0; j < 7; j++) {
          d.setDate(d.getDate() + count)
          month[i].push({ date: d.getDate(), month: d.getMonth(), year: d.getFullYear() })
          count = 1
        }
      }
      return month
    }
  }
}
</script>
<style scoped>
thead th {
  color: #FDB00B;
  text-transform: capitalize;
  font-size: 1rem;
}
tbody .notMonth {
  color: #dadada63;
  cursor: not-allowed;
}
tbody .date-now {
  color: #FDB00B !important;
}
tbody .date-mark {
  color: rgba(255, 255, 255, 0.7);
}
thead th, tbody td {
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
tbody td{
  position: relative;
  font-size: 1rem;
  border-radius: 3px;
}
tbody td:hover {
  background-color: rgba(0,0,0,.2)
}
</style>
