<template>
  <form-base
    @submeter="submeter"
    :loading="loading"
    :disable-submit-button="false"
    @close-dropdown="closeInputModal"
    @back-step="$router.go(-1)"
  >
  <container-fluid>
    <header-title title="1. Dados Pessoais" description="Insere os teus dados pessoais para criares o teu perfil Riser." />
    <div class="section-container">
      <section class="section">
        <div class="input-row">
          <div class="card-avatar">
            <div class="avatar-container">
              <div
              class="avatar-photo"
              :class="{ 'error': errors.avatar[Object.keys(errors.avatar)[0]] }"
              :data-title="!avatar.avatarUrl ? Object.keys(errors.avatar)[0]: ''">
              <img :src="avatar.avatarUrl">
              </div>
              <p class="avatar-title">Quem és tu?</p>
              <button  @click="getAvatar" type="button" class="btn-edit" data-input>
                <p data-input>Escolhe</p>
              </button>
            </div>
          </div>
          <div class="column card-input-name-nickname">
            <div class="input-wrapper">
              <input-form
                title="Primeiro Nome"
                :error="errors.nome[Object.keys(errors.nome)[0]] ? Object.keys(errors.nome)[0] : ''"
                v-model="$v.nome.$model"
                class="style"
                @focus="resetWidget"
              />
            </div>
            <div class="input-wrapper">
              <input-form
                title="Apelido"
                :error="errors.apelido[Object.keys(errors.apelido)[0]] ? Object.keys(errors.apelido)[0] : ''"
                v-model="$v.apelido.$model"
                @focus="resetWidget"
              />
            </div>
          </div>

        </div>
          <div class="input-row">
            <div class="input-wrapper">
              <input-calendar
                title="Data de Nascimento"
                :error="errors.dataNascimento[Object.keys(errors.dataNascimento)[0]] ? Object.keys(errors.dataNascimento)[0] : ''"
                field="w_isData"
                :isShow="w_isData"
                :value="dataNascimento"
                @setData="setBirthDay"
                v-on:w_isData="w_isData = !w_isData"
              />
            </div>
            <div class="input-wrapper">
            <input-datalist
              title="Género"
              :error="errors.sexo[Object.keys(errors.sexo)[0]] ? Object.keys(errors.sexo)[0] : ''"
              field="w_isSexo"
              align="top"
              :themeStyle="theme"
              :dataSource="genero"
              :value="sexo.nome"
              @setData="(value) => setData('sexo', value)"
              :isShow="w_isSexo"
              v-on:w_isSexo="toggleWidget"
            />
          </div>
          </div>
          <div class="input-wrapper">
              <input-datalist
                title="Nacionalidade"
                :error="errors.nacionalidade[Object.keys(errors.nacionalidade)[0]] ? Object.keys(errors.nacionalidade)[0] : ''"
                field="w_isNacionlidade"
                :themeStyle="theme"
                :dataSource="nacionalidades"
                :value="idNacionalidade.nome"
                @setData="(value) => setData('idNacionalidade', value)"
                :isShow="w_isNacionlidade"
                v-on:w_isNacionlidade="toggleWidget"
              />
            </div>
      </section>
      <section class="section">
        <div class="input-row">
          <div class="input-wrapper">
            <input-datalist
              title="Documento de identificação"
              :error="errors.tipoDoc[Object.keys(errors.tipoDoc)[0]] ? Object.keys(errors.tipoDoc)[0] : ''"
              field="w_isTipoDoc"
              :themeStyle="theme"
              :dataSource="listaTipoDoc"
              :value="tipoDoc.nome"
              align="top"
              @setData="(value) => setData('tipoDoc', value)"
              :isShow="w_isTipoDoc"
              v-on:w_isTipoDoc="toggleWidget"
            />
          </div>
          <div class="input-wrapper">
            <input-form
              title="Número"
              :error="errors.bi[Object.keys(errors.bi)[0]] ? Object.keys(errors.bi)[0] : ''"
              alert="minimo 6 digitos"
              v-model="$v.bi.$model"
              placeholder=""
              @focus="resetWidget"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="input-wrapper">
            <input-datalist
              title="País"
              :error="errors.country[Object.keys(errors.country)[0]] ? Object.keys(errors.country)[0] : ''"
              placeholder=""
              field="w_isCountry"
              :themeStyle="theme"
              :dataSource="paises"
              :value="country.nome"
              @setData="setCountry"
              :isShow="w_isCountry"
              v-on:w_isCountry="toggleWidget"
            />
          </div>
          <div class="input-wrapper">
            <input-datalist
              title="Região"
              align="bottom"
              :error="errors.cidade[Object.keys(errors.cidade)[0]] ? Object.keys(errors.cidade)[0] : ''"
              field="w_isLocalizacao"
              :themeStyle="theme"
              @setData="(value) => setData('idCidade', value)"
              @close-modal="showModal = false"
              placeholder=""
              :value="idCidade.nome"
              :isShow="w_isLocalizacao"
              :dataSource="cidades"
              v-on:w_isLocalizacao="toggleWidget"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="input-wrapper flex-1">
              <input-datalist
                title="Indicativo"
                align="top"
                :error="errors.indicativo[Object.keys(errors.indicativo)[0]] ? Object.keys(errors.indicativo)[0] : ''"
                placeholder=""
                field="w_isIndicativo"
                :dataSource="indicativos"
                :value="indicativo.nome"
                @setData="(value) => setData('indicativo', value)"
                :isShow="w_isIndicativo"
                v-on:w_isIndicativo="toggleWidget"
              />
          </div>
          <div class="input-wrapper flex-2">
            <input-phone-mask
              title="Telefone"
              :error="errors.phoneNumber[Object.keys(errors.phoneNumber)[0]] ? Object.keys(errors.phoneNumber)[0] : ''"
              alert="ex.: 923 00 00 00"
              v-model="$v.phoneNumber.$model"
              @input="$v.phoneNumber.$model = $event"
              :phoneNumber="$v.phoneNumber.$model"
              placeholder=""
              @focus="resetWidget"
              :rules="[{ minLength: 9 }]"
            />
          </div>
        </div>
        <div class="input-wrapper">
          <input-form
            title="E-mail"
            :error="errors.email[Object.keys(errors.email)[errors.email.key]] ? Object.keys(errors.email)[errors.email.key] : ''"
            placeholder=""
            v-model="$v.email.$model"
            @focus="resetWidget"
          />
        </div>
        <div class="input-row">
          <div class="input-wrapper">
            <input-form
              title="Palavra-passe"
              :error="errors.password[Object.keys(errors.password)[0]] ? Object.keys(errors.password)[0] : ''"
              alert="minimo 6 digitos"
              placeholder=""
              v-model="$v.password.$model"
              @focus="resetWidget"
              type="password"
            />
          </div>
          <div class="input-wrapper">
            <input-form
              title="Confirma Palavra-passe"
              :error="errors.repassword[Object.keys(errors.repassword)[0]] ? Object.keys(errors.repassword)[0] : ''"
              placeholder=""
              v-model="$v.repassword.$model"
              @focus="resetWidget"
              type="password"
            />
          </div>
        </div>
      </section>
    </div>
  </container-fluid>
    <avatar-modal v-if="showModal" @selectImage="(value) => setData('avatar', value)" @close-modal="() => setData('showModal', false)"/>
    <template #submit>
      <span>next</span>
    </template>
  </form-base>
</template>

<script>

import { mapMutations, mapGetters, mapState } from 'vuex'
import {
  required,
  minLength,
  email,
  numeric
} from 'vuelidate/lib/validators'

import BaseForm from '@/components/Form/base-form'
import AvatarModal from '@/components shared/Modal/PreviewModalAvatar'
import { validateEmail } from '@/api/services/candidatos'
export default {
  name: 'form-data-pessoal',
  props: {
    theme: Object
  },
  components: {
    'form-base': BaseForm,
    'input-form': require('@/components/Form/input-form').default,
    'input-phone-mask': require('@/components/Form/input-phone-mask').default,
    'input-datalist': require('@/components/Form/input-datalist').default,
    'input-calendar': require('@/components/Form/input-calendar').default,
    'header-title': require('@/components/Form/header-title').default,
    'avatar-modal': AvatarModal
  },
  computed: {
    ...mapGetters('register', [
      'paises',
      'indicativos',
      'genero',
      'listaTipoDoc'
    ]),
    nacionalidades () {
      return this.paises
    },
    ...mapState('register', ['register']),
    cidades () {
      return this.country ? this.country.cidades.filter(cidade => cidade.id !== 80) : []
    }
  },
  validations: {
    nome: {
      required,
      minLength: minLength(2)
    },
    apelido: {
      required,
      minLength: minLength(2)
    },
    bi: {
      required,
      minLength: minLength(6)
    },
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    },
    repassword: {
      required
    },
    phoneNumber: {
      required,
      minLength: minLength(9),
      numeric
    }
  },
  methods: {
    ...mapMutations('register', ['setState']),
    toggleWidget (property, value) {
      this.resetWidget()
      this[property] = !value
    },
    closeInputModal (isCloseDropdown, event) {
      if (event) {
        const path = Array.from(event.path).findIndex(item => {
          return item.hasAttribute && item.hasAttribute('data-input')
        })
        if (path !== -1) return
      }
      if (isCloseDropdown) {
        this.resetWidget()
      }
    },
    getAvatar () {
      this.showModal = true
    },
    valideField (field) {
      const key = Object.keys(this.errors[field])[0]
      this.errors[field][key] = false
    },
    resetWidget () {
      this.w_isSexo = false
      this.showModal = false
      this.w_isCountry = false
      this.w_isCidades = false
      this.w_isTipoDoc = false
      this.w_isLocalizacao = false
      this.w_isNacionlidade = false
      this.w_isData = false
      this.w_isIndicativo = false
    },
    submeter () {
      const {
        $v,
        sexo,
        avatar,
        errors,
        tipoDoc,
        country,
        idCidade,
        indicativo,
        dataNascimento,
        idNacionalidade
      } = this
      const { nome, apelido, email, password, repassword, phoneNumber, bi } = $v
      let ok = true
      Object.keys(this.errors).forEach(field => this.valideField(field))
      if (nome.$model === '' || nome.$error) {
        const property = Object.keys(errors.nome)[0]
        this.errors.nome[property] = true
        ok = false
      }
      if (apelido.$model === '' || apelido.$error) {
        const property = Object.keys(errors.apelido)[0]
        this.errors.apelido[property] = true
        ok = false
      }
      if (dataNascimento === '' || dataNascimento === null) {
        const property = Object.keys(errors.dataNascimento)[0]
        this.errors.dataNascimento[property] = true
        ok = false
      }
      if (bi.$model === '' || bi.$error) {
        const property = Object.keys(errors.bi)[0]
        this.errors.bi[property] = true
        ok = false
      }
      if (email.$model === '' || email.$error) {
        const property = Object.keys(errors.email)[0]
        this.errors.email[property] = true
        this.errors.email.key = 0
        ok = false
      }
      if (password.$model === '' || password.$error) {
        const property = Object.keys(errors.password)[0]
        this.errors.password[property] = true
        ok = false
      }

      if (password.$model !== '' && repassword.$model.localeCompare(password.$model) !== 0) {
        const property = Object.keys(errors.repassword)[0]
        this.errors.repassword[property] = true
        ok = false
      }
      if (indicativo === '' || indicativo === null) {
        const property = Object.keys(errors.indicativo)[0]
        this.errors.indicativo[property] = true
        ok = false
      }
      if (phoneNumber.$model === '' || phoneNumber.$error) {
        const property = Object.keys(errors.phoneNumber)[0]
        this.errors.phoneNumber[property] = true
        ok = false
      }
      if (sexo === '') {
        const property = Object.keys(errors.sexo)[0]
        this.errors.sexo[property] = true
        ok = false
      }
      if (idCidade === '') {
        const property = Object.keys(errors.cidade)[0]
        this.errors.cidade[property] = true
        ok = false
      }
      if (idNacionalidade === '') {
        const property = Object.keys(errors.nacionalidade)[0]
        this.errors.nacionalidade[property] = true
        ok = false
      }
      if (country === '') {
        const property = Object.keys(errors.country)[0]
        this.errors.country[property] = true
        ok = false
      }
      if (tipoDoc === '') {
        const property = Object.keys(errors.tipoDoc)[0]
        this.errors.tipoDoc[property] = true
        ok = false
      }
      if (avatar === '') {
        const property = Object.keys(errors.avatar)[0]
        this.errors.avatar[property] = true
        ok = false
      }
      if (!ok) {
        return
      }
      validateEmail(email.$model).then(res => {
        if (res) {
          const property = Object.keys(errors.email)[1]
          this.errors.email.key = 1
          this.errors.email[property] = true
          ok = false
          return
        }
        this.setState({
          sexo: sexo.id,
          numDoc: bi.$model,
          idPais: country?.id,
          idAvatar: avatar?.id,
          tipoDoc: tipoDoc?.id,
          nome: nome.$model,
          idCidade: idCidade?.id,
          email: email.$model,
          apelido: $v.apelido.$model,
          password: password.$model,
          dataNascimento: dataNascimento,
          idIndicativo: indicativo.id,
          phoneNumber: phoneNumber.$model,
          idNacionalidade: idNacionalidade?.id
        })
        this.errors.email.key = 0
        this.$emit('change-step', 1)
      })
    },
    setData (field, value) {
      this[field] = value
      if (field === 'avatar') {
        let generos = this.genero.filter(genero => genero.id !== -1)
        this.sexo = value.designacao === 'Masculino' ? generos[0] : generos[1]
      }
      this.resetWidget()
    },
    setCountry (value) {
      this.setData('country', value)
      this.idCidade = ''
    },
    setBirthDay (value) {
      value.setMonth(value.getMonth() - 1)
      this.setData('dataNascimento', value)
    },
    setMessageError (text, duraction = 5000) {
      this.textError = text
      this.invalidForm = true
      setTimeout(() => {
        this.textError = ''
        this.invalidForm = false
      }, duraction)
    }
  },
  data () {
    const { picklists, register } = this.$store.state.register
    const nacionalidade = picklists.nacionalidades.filter(nac => nac.id === this.$store.state.register.register.idNacionalidade)[0] || ''
    const country = picklists.paises.filter(item => item.id === register.idPais)[0] || ''
    let sexo = picklists.genero.find(item => item.id === register.sexo)
    if (!sexo) {
      sexo = { nome: '', id: -1 }
    }
    const idCidade = country ? country.cidades.filter(item => item.id === register.idCidade)[0] : ''
    const tipoDoc = picklists.tipoDoc.filter(item => item.id === register.tipoDoc)[0] || ''
    const avatar = picklists.avatares.filter(item => item.id === register.idAvatar)[0] || ''
    const indicativo = picklists.indicativos.find(item => item.id === register.idIndicativo) || ''
    return {
      w_isSexo: false,
      w_isPais: false,
      showModal: false,
      w_isTipoDoc: false,
      w_isCountry: false,
      w_isCidades: false,
      w_isIndicativo: false,
      w_isLocalizacao: false,
      w_isNacionlidade: false,
      w_isData: false,
      sexo,
      avatar,
      tipoDoc,
      country,
      idCidade,
      indicativo,
      loading: false,
      videoIndexError: 0,
      idNacionalidade: nacionalidade,
      bi: this.$store.state.register.register.numDoc,
      nome: this.$store.state.register.register.nome,
      email: this.$store.state.register.register.email,
      apelido: this.$store.state.register.register.apelido,
      password: this.$store.state.register.register.password,
      repassword: this.$store.state.register.register.password,
      phoneNumber: this.$store.state.register.register.phoneNumber,
      dataNascimento: this.$store.state.register.register.dataNascimento,
      errors: {
        avatar: {
          'Avatar mandatório': false
        },
        nome: {
          'Nome inválido': false
        },
        apelido: {
          'Apelido inválido': false
        },
        dataNascimento: {
          'Data de Nascimento inválida': false
        },
        nacionalidade: {
          'Nacionalidade inválida': false
        },
        country: {
          'País inválido': false
        },
        sexo: {
          'Gênero inváliado': false
        },
        cidade: {
          'Cidade inválida': false
        },
        tipoDoc: {
          'Tipo de documento inválido': false
        },
        bi: {
          'Identidade inválida': false
        },
        email: {
          'E-mail inválido': false,
          'Este e-mail já existe': false,
          key: 0
        },
        password: {
          'Palavra-passe inválida': false
        },
        repassword: {
          'Palavras Passes diferentes': false
        },
        indicativo: {
          'Indicativo inválido': false
        },
        phoneNumber: {
          'Número de telefone inválido': false
        }
      },
      error: ''
    }
  },
  beforeUpdate () {
    if (this.showModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-message {
  color: #f00;
  display: none;
  &.error {
    display: inline-block;
  }
}
.card-avatar {
  .card-avatar-title {
   color: #6a4c7cc0;
  }
}
.card-input-name-nickname {
  width: 100%;
  margin-left: 0;
  .input-wrapper {
    width: 100% !important;
  }
  @media screen and (min-width: 768px ) {
  flex: 100%;
  margin-left: 2rem;
  }
}
.section-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  .section {
    flex: 0.8;
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .avatar-container {
      width: 180px;
      height: 168px;
      margin: 0 auto;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 3px 10px #00000029;
      padding: 10px;
      position: relative;
      .avatar-title {
        color: #6a4c7cc0;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        width: 90%;
        margin-bottom: 5px;
      }
      .avatar-photo {
        margin: 0;
        margin-bottom: 5px;
        border: 2px solid #fdb00b;
        background-repeat: no-repeat;
        background-size: 60%;
        background-position: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          object-fit: cover;
          max-width: 95%;
          max-height: 95%;
        }
        &.error::before {
          color: #f00;
        }
        &::before {
          content: attr(data-title);
          color: transparent;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          height: 100%;
          width: 100%;
          font-size: 12px;
          z-index: -1;
        }
      }
      .btn-edit {
        background-color: #26a0a5;
        border: none;
        color: #fff;
        padding: 3px 10px;
        border-radius: 12px;
        cursor: pointer;
      }
    }
  }
  .input-wrapper, .input-row {
    width: 88%;
  }
  .input-wrapper {
    margin-top: 6px;
  }
  .input-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .input-wrapper {
      flex: 0.45;
      &.flex-1 {
        flex: 0.3;
      }
      &.flex-2 {
        flex: 0.65
      }
    }
  }
}
.label-wrappper-title2 {
  font-size: 18px;
  color: #4b335a;
  text-align: left;
  font-family: "Roboto", sans-serif !important;
  left: 0;
}
@media only screen and (max-width: 768px) {
  .section-container {
    flex-direction: column;
    align-items: center;
    .input-row {
      flex-direction: column;
      align-items: flex-start;
    }
    .input-wrapper, .input-row {
      width: 98%;
    }
    .input-wrapper {
      margin: 1rem 0;
    }
    .section {
      width: 88%;
      &:nth-child(2) {
        align-items: flex-start;
      }
    }
  }
}
</style>
