<template>
  <table>
    <table-caption @back="backYear" @next="nextYear" :label="`${currentYear}`" @callback="$emit('toggle-calendar', 'date')"/>
    <tbody>
      <tr v-for="(line, index) in yaers" :key="index">
        <td v-for="(year, index2) in line"
        :key="index2"
        :class="{active: currentYear === year}"
        @click="$emit('set-year', year)">
        <slot name="date" v-bind:data="{value: year}" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'calendar-year',
  components: {
    'table-caption': require('./caption').default
  },
  props: {
    currentYear: Number,
    matrix: {
      type: Number,
      default: 5
    },
    minAge: {
      type: Number,
      default: 18
    }
  },
  data () {
    return {
      YEAR: this.currentYear
    }
  },
  methods: {
    nextYear () {
      const year = new Date().getFullYear()
      const increment = this.matrix ** 2
      if ((this.YEAR + increment) <= year - this.minAge) {
        this.YEAR = this.YEAR + increment
      } else {
        this.YEAR = year - this.minAge
      }
    },
    backYear () {
      if (this.YEAR > 1000) { this.YEAR = this.YEAR - (this.matrix ** 2) + 1 }
    }
  },
  computed: {
    yaers () {
      const year = new Date().getFullYear()
      let dates = []
      const startYear = this.YEAR - parseInt(((this.matrix) * this.matrix) / 2)
      let count = startYear
      for (let i = 0; i < this.matrix; i++) {
        dates[i] = []
        for (let j = 0; j < this.matrix; j++) {
          if (count <= year - this.minAge) {
            dates[i][j] = count
          }
          count++
        }
      }
      return dates
    }
  }
}
</script>

<style scoped>
tbody td {
  width: 70px;
  height: 40px;
}
.active {
  color: #FDB00B ;
}
</style>
