<template>
  <caption>
    <div :class="{'center': noController}">
      <label v-if="!noController" for="backMonth" @click="$emit('back', -1)">
        <svg width="25" height="25" viewBox="0 0 25 25">
          <path d="M19,0 L6.5,12.5 L19,25" fill="transparent" stroke="#fff" stroke-width="1" />
        </svg>
      </label>
      <label @click="$emit('callback')" class="display-date">{{label}}</label>
      <label v-if="!noController" for="nextMonth" @click="$emit('next', 1)">
        <svg width="25" height="25" viewVox="0 0 25 25">
          <path d="M6.5,0 L19,12.5 L6.5,25" fill="transparent" stroke="#fff" stroke-width="1" />
        </svg>
      </label>
    </div>
  </caption>
</template>
<script>
export default {
  name: 'table-caption',
  props: {
    noController: {
      type: Boolean,
      default: false
    },
    label: String,
    currentDate: {
      type: Object,
      default: function () {
        const d = new Date()
        return {
          year: d.getFullYear(),
          month: d.getMonth(),
          date: d.getDate(),
          day: d.getDay()
        }
      }
    }
  },
  filters: {
    DateNow (value) {
      const months = ['jan', 'fev', 'mar', 'abril', 'maio', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
      const d = new Date(value.year, value.month, value.date)
      return months[d.getMonth()] + ' ' + d.getFullYear()
    }
  }
}
</script>

<style scoped>
.display-date {
  font-size: 1.3rem;
  text-transform: capitalize;
}
table caption  > div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.829)
}

table caption div label {
  cursor: pointer;
  align-self: flex-start;
  text-align: center;
  padding: .5rem;
  border-radius: 3px;
}
.center {
  justify-content: center;
}
table caption div label:active {
  background-color: rgba(0,0,0,.4)
}
</style>
